<template>
  <transition name="fade">
    <div
      v-if="show"
      class="success-result"
      :style="{
        background: `url('${require('@/assets/bg/modal-bg.png')}') center -120px no-repeat, linear-gradient(to left, #d20051 0%, #ffa300 100%)`,
      }"
    >
      <div
        class="success-result-text"
        :style="{
          background: `url('${require('@/assets/bg/coins.png')}') center top no-repeat`,
        }"
      ></div>
      <a @click="$emit('close')">
        <VueIcon
          class="result-close"
          :width="iconCross.width"
          :height="iconCross.height"
          :iconName="iconCross.name"
          :iconColor="iconCross.color"
        ></VueIcon
      ></a>
      <div class="result-text">
        <h2>Tebrikler</h2>
        <span>{{ successText }}</span>
      </div>
    </div>
  </transition>
</template>
<script>
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
export default {
  name: 'BrandSuccessResult',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    taskUserId: {
      type: Number,
    },
  },
  components: {
    VueIcon,
  },
  computed: {
    iconCross() {
      const iconCross = ICON_VARIABLES.cross;
      iconCross.color = '#ffffff';
      return iconCross;
    },
    successText() {
      return `${this.taskUserId ? 'Yap Kazanı' : 'Yarışmayı'} Başarı ile Tamamladınız`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_animations.scss';

.success-result {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  background-size: cover !important;

  .result-close {
    position: absolute;
    right: 0;
    top: 0;
    margin: palette-space-level(15);
  }
  .success-result-text {
    width: 100%;
    height: 100%;
    z-index: -1;

    background-size: 75vh !important;
    height: 75vh;
    position: initial;
    margin-bottom: 20px;
  }
  .result-text {
    color: palette-color-level('white', 100);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    h2 {
      margin-bottom: palette-space-level(20);
    }
  }
}
</style>
